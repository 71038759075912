import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Button } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import mobile from 'is-mobile';

import Copyright from './copyright';
import PrivacyPolicy from './privacyPolicy';

export default function StickyFooter() {
  const [privacyOpen, setPrivacyOpen] = useState(false);

  return (
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          width: '100vw',
          bottom: 0,
          backgroundColor: 'lightgrey',
        }}
      >
        <Container maxWidth="sm">
          <img style={{width: mobile() ? '10%' : '8%', marginTop: mobile() ? -15 : -100 }} src='/LLCareLogoIcon.jpg' alt='moplowLogo' />
          <Copyright />
          <Typography variant="body1">
            <Button
              variant='text'
              color='secondary'
              onClick={() => setPrivacyOpen(true)}
            >
              Privacy Policy
            </Button>
          </Typography>
          <Typography sx={{fontStyle: 'italic'}} color='grey' variant='body1'>
            Independently Owned and Operated
          </Typography>
          <Typography sx={{fontStyle: 'italic'}} color='grey' variant='body1'>
            Lawn Care
          </Typography>
          <Typography sx={{fontStyle: 'italic'}} color='grey' variant='body1'>
            Snow Removal
          </Typography>
          <hr/>
          <Typography sx={{textDecoration: 'underline double'}} color='grey' variant='h6'>
            CONTACT US
          </Typography>
          <br />
          <Typography color='primary' variant='body1'>
            <Button
                variant='text'
                onClick={() => window.location.href = 'tel://801-874-6947'}
            >
                <PhoneIcon />(801) 874-6947
            </Button>
          </Typography>
          <Typography color='primary' variant='body1'>
            <Button
              variant='text'
              onClick={() => window.location.href = 'mailto:Listerlawncare1@gmail.com'}
              >
                <EmailIcon /> Listerlawncare1@gmail.com
              </Button>
          </Typography>
          <Button 
            sx={{minWidth: '50px', marginBottom: '50px', marginTop: '10px'}}
            variant='contained'
            onClick={() => window.location.href = 'https://www.facebook.com/coy.lister?mibextid=LQQJ4d'}
          >
          <FacebookIcon />
          </Button>
          <Typography sx={{textDecoration: 'underline double'}} color='grey' variant='h6'>
            HOURS OF OPERATION
          </Typography>
          <br />
          <Typography color='grey' variant='body1'>
            <span style={{fontWeight: 'bold'}}>MON-FRI</span> 8:00 AM - 5:00 PM
          </Typography>
          <Typography sx={{marginBottom: '50px'}} color='grey' variant='body1'>
            <span style={{fontWeight: 'bold'}}>SAT-SUN</span> CLOSED
          </Typography>
          <Typography sx={{textDecoration: 'underline double'}} color='grey' variant='h6'>
            SERVICE AREAS
          </Typography>
          <br />
          <Typography color='grey' variant='body1'>Salt Lake County</Typography>
          <Typography color='grey' variant='body1'>Utah County</Typography>
        </Container>
        <PrivacyPolicy
          privacyOpen={privacyOpen}
          setPrivacyOpen={setPrivacyOpen}
        />
      </Box>
  );
}