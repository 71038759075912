import React from 'react';
import './App.css';
import AppLayout from './layouts/appLayout';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppLayout />
      </header>
    </div>
  );
}

export default App;
