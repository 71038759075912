import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import mobile, { isMobile } from 'is-mobile';
import GrassIcon from '@mui/icons-material/Grass';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import PhoneIcon from '@mui/icons-material/Phone';
import InfoIcon from '@mui/icons-material/Info';

import StickyFooter from '../components/stickyFooter';

export default function LandingPage() {
    return (
        <div style={{minHeight: '100vh'}}>
            <Grid container sx={{minWidth: '100%', minHeight: '100vh'}}>
                <Grid 
                    item 
                    xs={0} 
                    lg={1}
                    sx={{background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(105,146,70,1) 69%, rgba(138,170,111,1) 100%)'}}
                ></Grid>
                <Grid item xs={12} lg={10} sx={{ padding:5}}>
                    <img style={{width: mobile() ? '80%' : '50%', marginTop: -20 }} src='/LLCareLogoNormal.png' alt='LLCareLogoText' />
                    <hr style={{marginBottom: '30px'}} />
                    <Grid container={!mobile()} spacing={!mobile() ? 4 : undefined}>
                        <Grid sx={{marginBottom: '20px'}} item xs={12} lg={6}>
                            <img style={{width: mobile() ? '100%': '75%', borderRadius: '10px'}} src={'/mowedhouse.jpg'} alt='mowedhouse' />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Typography variant={mobile() ? 'body2' : 'inherit'} sx={{margin: 'auto'}}>Lister Lawn Care is a one-stop servicer for your year-round curb appeal needs. Our lawn care and snow removal services will help save you time and allow you to get the most out of your favorite seasons. We have the equipment and dedication to get the job done right with no hassle.</Typography>
                            <Button
                                variant='contained'
                                sx={{marginTop: '50px'}}
                                onClick={() => window.location.href = 'tel://801-874-6947'}
                            >
                                <PhoneIcon sx={{marginRight: '5px'}} /> Call now for a free quote!
                            </Button>
                            <Button
                                style={{display: 'block', margin: 'auto'}}
                                variant='text'
                                onClick={() => window.location.href = 'tel://801-874-6947'}
                            >
                                (801) 874-6947
                            </Button>
                        </Grid>
                    </Grid>
                    <Typography sx={{marginTop: mobile() ? 5 : 8, marginBottom: mobile() ? 5 : 8, textDecoration: 'underline overline', textDecorationColor: 'rgba(14,179,250,1)'}} variant={mobile() ? 'h4' : 'h2'}>Services</Typography>
                    <Grid container={!mobile()} spacing={!mobile() ? 4 : undefined}>
                        <Grid sx={{marginBottom: '20px'}} item xs={12} lg={6}>
                            <Typography sx={{marginBottom: '10px', background: "green"}} variant={mobile() ? 'h5' : 'h4'}><GrassIcon sx={{marginRight: '5px'}}/>LAWN CARE</Typography>
                            <Typography variant={mobile() ? 'body2' : 'inherit'} sx={{margin: 'auto'}}>We strive to take care of your lawn as well as we do our own. With our expertise and attention to detail, it will be our pleasure to give you the lawn you deserve.</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <img style={{width: mobile() ? '100%': '75%', borderRadius: '10px'}} src='/mowedgolf.jpg' alt='mowedgolf' />
                        </Grid>
                    </Grid>
                    <Grid sx={{marginTop: '50px'}} container={!mobile()} spacing={!mobile() ? 4 : undefined}>
                        <Grid sx={{marginBottom: '20px'}} item xs={12} lg={6}>
                            <Typography sx={{marginBottom: '10px', background: '#00A0A0'}} variant={mobile() ? 'h5' : 'h4'}><AcUnitIcon sx={{marginRight: '5px'}} />SNOW REMOVAL</Typography>
                            <Typography variant={mobile() ? 'body2' : 'inherit'} sx={{margin: 'auto'}}>It is our intention to help keep people safe during the winter season. We will remove snow and ice from any commonly pathed surface: roads, parking lots, pavement, and driveways.</Typography>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <img style={{width: mobile() ? '100%': '75%', borderRadius: '10px'}} src='/snowplowedRoad.jpg' alt='snowplowedRoad' />
                        </Grid>
                    </Grid>
                    <Grid sx={{marginTop: '50px'}} container={!mobile()} spacing={!mobile() ? 4 : undefined}>
                        <Grid item xs={12} lg={6}>
                            { isMobile() && 
                                <Typography sx={{marginBottom: '10px', background: '#0C1B4A'}} variant={mobile() ? 'h5' : 'h4'}><InfoIcon sx={{marginRight: '5px'}} />ABOUT US</Typography>
                            }
                            <img style={{width: mobile() ? '100%': '75%', borderRadius: '10px'}} src='/coy.jpg' alt='coy' />
                        </Grid>
                        <Grid sx={{marginBottom: '20px'}} item xs={12} lg={6}>
                             { !isMobile() && 
                                <Typography sx={{marginBottom: '10px', background: '#0C1B4A'}} variant={mobile() ? 'h5' : 'h4'}><InfoIcon sx={{marginRight: '5px'}} />ABOUT US</Typography>
                            }
                            <Typography variant={mobile() ? 'body2' : 'inherit'} sx={{margin: 'auto'}}>My name is Coy Lister. Good curb appeal is my passion. I started working in lawn care at a young age. Over the years I have built upon this foundation into an established independently owned and operated lawn care and snow removal business. My team and I are dedicated, hard working and detail oriented. We got you covered.</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid 
                    item 
                    xs={0} 
                    lg={1}
                    sx={{background: 'linear-gradient(0deg, rgba(2,0,36,1) 0%, rgba(105,146,70,1) 69%, rgba(138,170,111,1) 100%)'}}
                ></Grid>
            </Grid>
            <StickyFooter />
        </div>
    );
};